import { NgModule } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';

import { NgxsModule } from '@ngxs/store';
import { NgxsModuleOptions, NoopNgxsExecutionStrategy } from '@ngxs/store';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';

import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

import { environment } from '../../../environments/environment';
import { AuthState } from './auth';
import { SwitchState } from './switch';
import { ClientState } from './client';
import { DialogState } from './dialog';
import { SwitchThemeState } from './theme';
import { MenuState } from './menu';
import { StatisticState } from './statistic/statistic.state';
import { BankState } from './bank';
import { BillState } from './bill/bill.state';
import { AmountState } from './amount';

export const ngxsConfig: NgxsModuleOptions = {
    developmentMode: !environment.production,
    selectorOptions: {
        // These Selector Settings are recommended in preparation for NGXS v4
        // (See above for their effects)
        suppressErrors: false,
        injectContainerState: false,
    },
    compatibility: {
        strictContentSecurityPolicy: true,
    },
    // Execution strategy overridden for illustrative purposes
    // (only do this if you know what you are doing)
    // executionStrategy: NoopNgxsExecutionStrategy
};

// @NgModule({
//   declarations: [],
//   imports: [
//     CommonModule
//   ]
// })

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        NgxsModule.forRoot(
            [
                AuthState,
                SwitchState,
                ClientState,
                DialogState,
                SwitchThemeState,
                MenuState,
                StatisticState,
                BankState,
                BillState,
                AmountState,
            ],
            ngxsConfig
        ),
        // ...environment.ngxsPlugins,
        NgxsStoragePluginModule.forRoot({
            key: [
                'auth.user.token',
                'auth.corporates',
                'plateform.plateform',
                'theme.theme',
                'menu.selectedMenu',
                'auth.client.clientId',
                'bank.selectedBank',
                'menu.selectedMarket',
            ],
        }),
        NgxsResetPluginModule.forRoot(),
        // NgxsLoggerPluginModule.forRoot(),
        NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
    ],
})
export class StatesModule {}
