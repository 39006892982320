import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';

import { ApiService } from './api.service';
import { PaginationConfig } from '../models';

@Injectable({
    providedIn: 'root',
})
export class ClientService {
    apiurl: any;
    private _addedAccount: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    get addedAccount$(): Observable<boolean> {
        return this._addedAccount.asObservable();
    }

    private _shownBalance: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);

    get shownBalance$(): Observable<boolean> {
        return this._shownBalance.asObservable();
    }
    private _hasAccountList: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    get hasAccountList$(): Observable<boolean> {
        return this._hasAccountList.asObservable();
    }
    private _hasAccountListWorkStation: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    get hasAccountListWorkStation$(): Observable<boolean> {
        return this._hasAccountListWorkStation.asObservable();
    }
    // private _selectedAccount: BehaviorSubject<any> = new BehaviorSubject<any>(
    //     null
    // );
    // get selectedAccount$(): Observable<any> {
    //     return this._selectedAccount;
    // }

    // selectAccount(account: any) {
    //     this._selectedAccount.next(account);
    // }

    private _isDetailsAccountShown: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    get isDetailsAccountShown$(): Observable<boolean> {
        return this._isDetailsAccountShown.asObservable();
    }
    private _isDetailsWalletShown: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    get isDetailsWalletShown$(): Observable<boolean> {
        return this._isDetailsWalletShown.asObservable();
    }

    hasAccountList() {
        this._hasAccountList.next(!this._hasAccountList.value);
    }
    hasAccountListWorkStation() {
        this._hasAccountListWorkStation.next(
            !this._hasAccountListWorkStation.value
        );
    }
    private _hasWalletList: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    get hasWalletList$(): Observable<boolean> {
        return this._hasWalletList.asObservable();
    }

    hasWalletList() {
        this._hasWalletList.next(!this._hasWalletList.value);
    }

    showAccountForm() {
        this._addedAccount.next(false);
    }

    constructor(private apiService: ApiService) {
        //comment
    }
    toggleAccount() {
        this._addedAccount.next(!this._addedAccount.value);
    }

    toggleAccountDetails(arg: boolean) {
        this._isDetailsAccountShown.next(arg);
    }
    isAccountList(arg: boolean) {
        this._hasAccountList.next(arg);
    }
    isAccountListWorkStation(arg: boolean) {
        this._hasAccountListWorkStation.next(arg);
    }
    isWalletList(arg: boolean) {
        this._hasWalletList.next(arg);
    }
    toggleWalletDetails(arg: boolean) {
        this._isDetailsWalletShown.next(arg);
        this._hasWalletList.next(arg);
    }

    showBalance() {
        this._shownBalance.next(!this._shownBalance.value);
    }

    getClientList(pagination: any = {}) {
        let pagination_: any;
        if (pagination.filters) {
            pagination_ =
                'limit=' +
                pagination.filters.limit +
                '&offset=' +
                pagination.filters.offset;
        }
        return this.apiService.get('/clients/list/all/?' + pagination_).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getClientDetails(clientId: string) {
        return this.apiService.get('/clients/list/all/' + clientId + '/').pipe(
            map((data) => {
                return data;
            })
        );
    }

    getClientAccounts(clientId: string) {
        return this.apiService.get('/accounts/' + clientId + '/').pipe(
            map((data) => {
                return data;
            })
        );
    }

    getAccountHistory(accountId: string) {
        const apiUrl = `/operations/all/statement/?trans_client_account_obj=${accountId}`;
        return this.apiService.get(apiUrl).pipe(map((data) => data));
    }

    getClientAccountStatus(accountId: string) {
        const apiUrl = `/account/status/list/?account_id=${accountId}`;
        return this.apiService.get(apiUrl).pipe(map((data) => data));
    }

    getClientAccountDetails(selectedAccount: string) {
        const apiUrl = '/clients/manage/accounts/' + selectedAccount + '/';
        return this.apiService.get(apiUrl).pipe(map((data) => data));
    }
    updateClientAccountDetails(selectedAccount: string, data: any) {
        const apiUrl = '/clients/manage/accounts/' + selectedAccount + '/';
        return this.apiService.put(apiUrl, data).pipe(map((data) => data));
    }

    getIndividualClientDetails(clientId: string) {
        const apiUrl = '/clients/manage/individuals/' + clientId + '/';
        return this.apiService.get(apiUrl).pipe(map((data) => data));
    }

    getCorporateClientDetails(clientId: string) {
        const apiUrl = '/clients/manage/corporate/' + clientId + '/';
        return this.apiService.get(apiUrl).pipe(map((data) => data));
    }

    createCorporate(body: any) {
        const url = '/client/corporate/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }

    changeAccountStatus(body: any) {
        const url = '/accounts/status/change/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }

    getAccountTypeList(
        clientCategoryId: any,
        clientTypeCategoryId: any,
        branchId: any
    ) {
        const apiUrl = `/account/config/defaults/?client_category=${clientCategoryId}&client_category_type=${clientTypeCategoryId}&branch=${branchId}`;
        return this.apiService.get(apiUrl).pipe(map((data) => data));
    }

    changeAccountType(body: any) {
        const url = '/account/config/defaults/update/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }

    getAccountCalculatedBalance(accountId: any) {
        const url = `/account/${accountId}/transactions/balance/`;
        return this.apiService.get(url).pipe(map((data) => data));
    }

    getAccountConfigurationsList() {
        const url = `/clients/config/accountpreset/`;
        return this.apiService.get(url).pipe(map((data) => data));
    }
    addAccountConfigurationsToClient(body: any) {
        const url = `/account/set/`;
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }

    getLanguages() {
        const apiUrl = '/languages/';
        return this.apiService.get(apiUrl).pipe(map((data) => data));
    }

    getClientLanguage(clientId: string) {
        const apiUrl = `/client/getelement/?client_id=${clientId}&element=language&phone_number=`;
        return this.apiService.get(apiUrl).pipe(map((data) => data));
    }

    UpdateIndividualClientDetails(clientId: string, data: any) {
        return this.apiService
            .patch(`/clients/manage/individuals/${clientId}/`, data)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    modifySectorCorporate(
        clientId: any,

        sectorId: any,
        data: any
    ) {
        return this.apiService
            .post(
                `/client/elements/update/${clientId}/?&activity_sector=${sectorId}`,
                data
            )
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
    modifyCategoryCorporate(
        clientId: any,
        categoryId: any,

        data: any
    ) {
        return this.apiService
            .post(
                `/client/elements/update/${clientId}/?client_category=${categoryId}`,
                data
            )
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    modifyClientLanguage(clientId = '', langCode: string, data: any) {
        return this.apiService
            .post(
                `/client/elements/update/${clientId}/?language=${langCode}`,
                data
            )
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    UpdateCorporateDetails(clientId: string, data: any) {
        return this.apiService
            .patch(`/clients/manage/corporate/${clientId}/`, data)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
    getClientEmailsAndTelephones(clientId: any) {
        return this.apiService
            .get(`/client/extid/?client=${clientId}&id_type=E`)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    getWallets(clientId: string) {
        return this.apiService.get('/dbs/wallets/?client_id=' + clientId).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getWalletsList() {
        return this.apiService.get(`/dbs/wallets/?`).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getWalletDetails(selectedWallet: string) {
        const apiUrl = '/dbs/wallets/' + selectedWallet + '/';
        return this.apiService.get(apiUrl).pipe(map((data) => data));
    }

    addAnEmail(body: any) {
        return this.apiService.post('/extid/creation/', body).pipe(
            map((data) => {
                return data;
            })
        );
    }

    addAphoneNumber(body: any) {
        return this.apiService.post('/extid/creation/', body).pipe(
            map((data) => {
                return data;
            })
        );
    }

    createAccount(body: any) {
        const url = '/account/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }

    addTaxation(body: any) {
        const url = '/client/fisc/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }

    addSignatoriesAccount(body: any) {
        const url = '/client/erp-signatories-accounts/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }

    addSignatoryConfig(body: any) {
        const url = '/client/erp-signatories-configs/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }
    getSignaroryConfigList(pagination?: PaginationConfig) {
        return this.apiService
            .get(
                `/client/erp-signatories-configs/?limit=${pagination?.filters.limit}&offset=${pagination?.filters.offset}`
            )
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
    getSignaroryConfigDetails(configurationId: any) {
        return this.apiService
            .get(`/client/erp-signatories-configs/${configurationId}/`)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
    getSignaroryConfigGroups() {
        return this.apiService.get(`/client/erp-signatory-groups/`).pipe(
            map((data) => {
                return data;
            })
        );
    }
    getSignarories() {
        return this.apiService.get(`/client/erp-signatories/`).pipe(
            map((data) => {
                return data;
            })
        );
    }
    getSignaroriesAccounts() {
        return this.apiService.get(`/client/erp-signatories-accounts/`).pipe(
            map((data) => {
                return data;
            })
        );
    }

    addSignatoryGroups(body: any) {
        const url = '/client/erp-signatory-groups/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }
    addSignatories(body: any) {
        const url = '/client/erp-signatories/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }
    searchClient(organizationId: number, accountId: any) {
        return this.apiService
            .get(
                `/account/details/?organization_pk=${organizationId}&account_id=${accountId}`
            )
            .pipe(map((data) => data));
    }

    modifyTaxInfo(fiscId: any, body: any) {
        const url = `/client/fisc/${fiscId}/`;
        return this.apiService
            .patch(url, body)
            .pipe(map((response) => response));
    }

    DoClientAutocomplete(search = '') {
        return this.apiService
            .get('/clients/list/all/objects_autocomplete?search=' + search)
            .pipe(map((data) => data));
    }

    DoClientLookup(search = '') {
        return this.apiService
            .get('/clients/list/all/object_lookup?lookup_data=' + search)
            .pipe(map((data) => data));
    }

    /* The below services are for Online Banking */

    getClientBankList() {
        return this.apiService
            .get('/banks/clientlist/')
            .pipe(map((data) => data));
    }

    // getDefaultBank() {
    //     return this.apiService
    //         .get('/banks/default-bank/')
    //         .pipe(map((data) => data));
    // }

    getRefereePersons() {
        return this.apiService
            .get('/client/refered/')
            .pipe(map((data) => data));
    }
}
