import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoanService {
    private _simulationResult: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );

    get simulationResult$(): Observable<any> {
        return this._simulationResult.asObservable();
    }

    private _loansLength: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    get loansLength$(): Observable<any> {
        return this._loansLength.asObservable();
    }

    private _showLoanDetails: BehaviorSubject<boolean> =
        new BehaviorSubject<any>(false);

    get showLoanDetails$(): Observable<boolean> {
        return this._showLoanDetails.asObservable();
    }

    private _loanValue: BehaviorSubject<any> = new BehaviorSubject<any>(
        undefined
    );

    get loanValue$(): Observable<any> {
        return this._loanValue.asObservable();
    }

    switchLoanDetails() {
        this._loanValue.next(undefined);
    }

    toggleLoanDetails(arg: boolean) {
        this._showLoanDetails.next(arg);
    }
    constructor(private apiService: ApiService) {}

    getLoansLength(length: number) {
        this._loansLength.next(length);
    }

    getLoansList() {
        const url = '/loans/clients/list/';
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getLoansListwithPagination(data: any) {
        const url = `/loans/clients/list/?limit=${data.limit}&offset=${data.offset}`;
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getSimulationResult(result: any) {
        this._simulationResult.next(result);
    }

    simulateLoan(body: any) {
        const url = '/loans/simulation/';
        return this.apiService.post(url, body).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getLoanDetails(loanId: string) {
        const url = '/loans/manage/';
        return this.apiService.get(url + loanId).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getAmortissmentPlan(id: string) {
        const url = '/loans/plan/';
        return this.apiService.get(url + id).pipe(
            map((data) => {
                return data;
            })
        );
    }

    payLoan(data: any) {
        const url = '/loans/clients/plan/payment/';
        return this.apiService.post(url, data).pipe(
            map((data) => {
                return data;
            })
        );
    }

    requestLoan(data: any) {
        const url = '/loans/request/';
        return this.apiService.post(url, data).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getLoanType() {
        const url = '/loans/loan-type/';
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getLoanTypeInfo(data: any) {
        const url = `/loans/defaults-check/?account=${data.account_id}&loan_type=${data.loan_type_id}`;
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getLoanRequestDetails(loanId: string) {
        const url = `/loans/request/${loanId}`;
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getPendingLoans(data: any) {
        const url = `/loans/request/?limit=${data.limit}&offset=${data.offset}&client_id=${data.client_id}`;
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getLoanListByClient(client_id: any) {
        const url = `/loans/manage/?cred_client_main_account__acc_client=${client_id}`;
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }
    getLoanLineListByClient(client_id: any) {
        const url = `/clients/manage/creditline/?crel_account__acc_client=${client_id}`;
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getLoanDetailsByClient(client_id: any, accountId: any) {
        const url = `/loans/manage/?cred_client_main_account__acc_client=${client_id}&cred_client_main_account=${accountId}`;
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }
}
