import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { GeneralService } from '..';
import { BehaviorSubject, Observable, map, retry } from 'rxjs';
import { HttpClient } from '@angular/common/http';
@Injectable({
    providedIn: 'root',
})
export class MerchantService {
    constructor(
        private apiService: ApiService,
        private http: HttpClient,
        private generalService: GeneralService
    ) {}

    // getMerchantList() {
    //     return this.apiService.get('/dbs/merchant/list/?').pipe(
    //         map((data) => {
    //         return data;
    //     })
    // );

    // }

    private _coords: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    get coords$(): Observable<any> {
        return this._coords.asObservable();
    }

    getUserCoords(coords: any) {
        this._coords.next(coords);
        console.log('coords', coords);
    }

    getMerchants(data: any) {
        const url = `/dbs/merchant/manage/?limit=${data.limit}&offset=${data.offset}`;
        return this.apiService.get(url).pipe(
            // retry({ count: 5, delay: 3000, resetOnSuccess: true }),
            map((data) => {
                return data;
            })
        );
    }

    getMerchantsAutocomplete(search: string) {
        const url =
            '/dbs/merchant/manage/objects_autocomplete/?search=' +
            search +
            '&is_recent=true';
        return this.apiService.get(url).pipe(
            retry({ count: 5, delay: 3000, resetOnSuccess: true }),
            map((data) => {
                return data;
            })
        );
    }
    getFavoriteMerchantsAutocomplete(search: string) {
        const url =
            '/dbs/merchant/manage/objects_autocomplete/?search=' +
            search +
            '&is_favorite=true';
        return this.apiService.get(url).pipe(
            retry({ count: 5, delay: 3000, resetOnSuccess: true }),
            map((data) => {
                return data;
            })
        );
    }
    makeFavoriteMerchants(favorite: any) {
        const url = '/dbs/merchant-client/favorite/';
        return this.apiService.post(url, favorite).pipe(
            retry({ count: 5, delay: 3000, resetOnSuccess: true }),
            map((data) => {
                return data;
            })
        );
    }
    doBillAction(body: any) {
        const url = '/dbs/merchant/bill/action/perform/';
        return this.apiService.post(url, body).pipe(
            retry({ count: 5, delay: 3000, resetOnSuccess: true }),
            map((data) => {
                return data;
            })
        );
    }
    getMerchantsDetails(id: any) {
        const url = '/dbs/merchant/manage/';
        return this.apiService.get(url + id).pipe(
            map((data) => {
                return data;
            })
        );
    }
    getMerchantsDetailsWorkstation(id: any) {
        const url = '/dbs/merchant/manage/';
        return this.apiService.get(url + id + '/?is_workstation=true').pipe(
            map((data) => {
                return data;
            })
        );
    }

    getTopClientsByAmount(merchantId: any) {
        const url = `/dbs/merchant/top-clients/${merchantId}/?by_amount=true`;

        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }
    getTopClientsByTransactions(merchantId: any) {
        const url = `/dbs/merchant/top-clients/${merchantId}/?by_transaction=true`;
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }
    getPaymentStats(merchantId: any) {
        const url = `/dbs/merchant/payment_stats/${merchantId}/`;
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }
    UpdateMerchantDetails(id: string, data: any) {
        const url = '/dbs/merchant/manage/';
        return this.apiService.patch(url + id + '/', data).pipe(
            map((data) => {
                return data;
            })
        );
    }
    getMerchantsCategoriesDetails(id: any) {
        const url = '/dbs/merchant-category/';
        return this.apiService.get(url + id).pipe(
            map((data) => {
                return data;
            })
        );
    }
    UpdateMerchantCategoriesDetails(id: string, data: any) {
        const url = '/dbs/merchant-category/';
        return this.apiService.patch(url + id + '/', data).pipe(
            map((data) => {
                return data;
            })
        );
    }
    getMerchantsBillsDetails(id: any) {
        const url = '/dbs/merchant/bills/';
        return this.apiService.get(url + id).pipe(
            map((data) => {
                return data;
            })
        );
    }
    UpdateMerchantBillsDetails(id: string, data: any) {
        const url = '/dbs/merchant/bills/';
        return this.apiService.patch(url + id + '/', data).pipe(
            map((data) => {
                return data;
            })
        );
    }
    getMerchantsProductsDetails(id: any) {
        const url = '/dbs/merchant-product/' + id + '/';
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }
    getConnectedMerchantInfo() {
        const url = '/dbs/merchant/info/';
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }
    getMerchantInfos(merchantId: any) {
        const url = '/dbs/merchant/merchant-info/' + merchantId + '/';
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getMerchantMultipleInfo() {
        const url = '/dbs/merchant/multiple-info/';
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }

    UpdateMerchantProductsDetails(id: string, data: any) {
        const url = '/dbs/merchant-product/';
        return this.apiService.patch(url + id + '/', data).pipe(
            map((data) => {
                return data;
            })
        );
    }
    UpdateMerchantTellersDetails(id: string, data: any) {
        return this.apiService.patch('/dbs/merchant-teller/' + id, data).pipe(
            map((data) => {
                return data;
            })
        );
    }

    updateMerchantDetails(body: any) {
        return this.apiService.post('/dbs/merchant/configuration/', body).pipe(
            map((body) => {
                return body;
            })
        );
    }

    getMerchantsCategories() {
        const url = '/dbs/merchant-category/';
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }
    getMerchantsTellers() {
        const url = '/dbs/merchant-teller/';
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }
    getMerchantsTellersDetails(id: any) {
        const url = '/dbs/merchant-teller/';
        return this.apiService.get(url + id).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getMerchantsBills() {
        const url = '/dbs/merchant/bills/';
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }
    getCLients() {
        const url = '/clients/list/all/objects_autocomplete?search=';
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }
    getMerchantsProducts() {
        const url = '/dbs/merchant-product/';
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getProductsByMerchant(merchantId: string) {
        const url = '/dbs/merchant-product/?merchant=' + merchantId + '&';

        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }
    createNewTeller(body: any) {
        const url = '/dbs/merchant-teller/';
        return this.apiService.post(url, body).pipe(
            map((data) => {
                return data;
            })
        );
    }
    createNewMerchant(body: any) {
        const url = '/dbs/merchant/creation/';
        return this.apiService.post(url, body).pipe(
            map((data) => {
                return data;
            })
        );
    }
    createNewCategory(body: any) {
        const url = '/dbs/merchant-category/';
        return this.apiService.post(url, body).pipe(
            map((data) => {
                return data;
            })
        );
    }
    createNewAgent(body: any) {
        const url = '/dbs/agent/bank/creation/';
        return this.apiService.post(url, body).pipe(
            map((data) => {
                return data;
            })
        );
    }
    getActivitySectors() {
        const url = '/clients/config/activitysector/';
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }
    getCategoriesPerActivitySectors(id: any) {
        const url = '/dbs/merchant-category/?merchant_activity_sector=' + id;
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getMerchantsByCategory(categoryId: any) {
        const url = '/dbs/merchant/list/?category_id=' + categoryId;
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getMerchantProductLookup(lookupData: any) {
        const url = '/dbs/merchant/product/lookup/';
        return this.apiService.post(url, lookupData).pipe(
            map((data) => {
                return data;
            })
        );
    }

    payMerchant(paymentData: Array<string | number | null>) {
        const url = '/dbs/merchant/product/payment/';
        return this.apiService.post(url, paymentData).pipe(
            map((data) => {
                return data;
            })
        );
    }
    checkMerchantTitle(merchantName: string) {
        const apiUrl = `/dbs/merchant/verify-merchant-title/?merchant_title=${merchantName}`;
        return this.apiService.get(apiUrl).pipe(map((data) => data));
    }
    checkTellerAlias(merchantId: string, merchantTellerName: string) {
        const apiUrl =
            '/dbs/merchant/verify-merchant-teller-alias/?merchant_id=' +
            merchantId +
            '&merchant_teller_alias=' +
            merchantTellerName +
            '';
        return this.apiService.get(apiUrl).pipe(map((data) => data));
    }

    searchProduct(pagination: any = {}, search = '') {
        let pagination_: any;
        if (pagination.filters) {
            pagination_ =
                'limit=' +
                pagination.filters.limit +
                '&offset=' +
                pagination.filters.offset;
        }
        const url =
            '/dbs/merchant-product/objects_autocomplete/?' +
            pagination_ +
            '&search=' +
            search;
        return this.apiService.get(url).pipe(map((data) => data));
    }
    getBIllers(biller: any) {
        const url = '/dbs/merchant/manage/objects_autocomplete/?is_biller=';
        return this.apiService.get(url + biller).pipe(map((data) => data));
    }

    getBillActions(billId: string) {
        const url = `/dbs/merchant/bills/${billId}/object_actions/`;
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }

    merchantCashin(data: any) {
        const url = '/dbs/merchant/cashin/';

        return this.apiService.post(url, data).pipe(map((data) => data));
    }

    getTellersByMerchant(merchantId: string) {
        const url = `/dbs/merchant-teller/?merchant=${merchantId}`;
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }

    doTellerAction(body: any) {
        const url = '/dbs/merchant-teller/teller/action/';

        return this.apiService.post(url, body).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getMerchantsByCategoriesSlug(slug: string) {
        const url =
            '/dbs/merchant/manage/objects_autocomplete?category_slug=' + slug;
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }

    updateProductInfo(body: any) {
        const url = '/dbs/merchant/product/configuration/';

        return this.apiService.post(url, body).pipe(
            map((data) => {
                return data;
            })
        );
    }

    searchProductByMerchant(data: any) {
        const url =
            '/dbs/merchant-product/objects_autocomplete/?merchant=' +
            data.merchant +
            '&search=' +
            data.search;
        return this.apiService.get(url).pipe(map((data) => data));
    }

    searchTellersByMerchant(data: any) {
        const url =
            '/dbs/merchant-teller/objects_autocomplete/?merchant=' +
            data.merchant +
            '&search=' +
            data.search;
        return this.apiService.get(url).pipe(map((data) => data));
    }

    getMerchantsLocation() {
        const url = '/dbs/merchant/maplist/';
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }
}
