import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';

import { ClientService, TransferService, BankService } from '../../../core';

import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import {
    SwitchThemeState,
    AuthState,
    BankState,
    SelectClientBank,
    SwitchState,
    AmountState,
    displayAmount,
    OpenDialog,
} from '../../../shared';

import { SwitchBankComponent } from '../switch-bank/switch-bank.component';
import { GeneralModule } from '../../../shared/components/general';
import { TicketingComponent } from '../reusable/ticketing/ticketing.component';
import { LookupIndividualComponent } from 'src/app/modules/workstation/counter/lookup-individual/lookup-individual.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
    selector: 'app-my-accounts',
    templateUrl: './my-accounts.component.html',
    styleUrls: ['./my-accounts.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SwitchBankComponent,
        GeneralModule,
        TicketingComponent,
        LookupIndividualComponent,
        NgxSkeletonLoaderModule,
    ],
})
export class MyAccountsComponent {
    debitAccount: any;
    selectedDebitAccountType = '';
    lookupDebitAccountUrl = '/clients/list/all/object_lookup?lookup_data=';
    clientId: any;
    clientId$: any;
    connectedClient: any;
    plateform: any;
    plateform$: any;
    accounts: any;
    wallets: any;
    isLoading = false;
    debitWallet: any;
    defaultBank: any;
    selectedBank: any;
    selectedBank$: any;
    bankId$: any;
    bankId: any;
    banks: any;
    clientBanks: any;

    // wallet information
    lookupWallet: any = new FormControl('');
    walletSearching = false;
    walletNumber: string | undefined;
    walletName: string | undefined;

    theme$: any;
    theme = '';
    index = 0;
    isBanksListShown = false;
    isBalanceShown = false;
    lookupType = '';
    isBalanceShown$: Observable<boolean>;

    @Output() debitOptions: EventEmitter<{
        account: any;
        wallet: any;
        selectedDebitOption: any;
        creditAccountType: any;
        isTransferDone: boolean;
        isAmountChanging: boolean;
        selectedInstitutionType: string;
        selectedInstitution: string;
    }> = new EventEmitter();

    @Output() amount: EventEmitter<number> = new EventEmitter();
    @Output() deselectAccount: EventEmitter<boolean> = new EventEmitter(false);

    @Output() lookupOptions: EventEmitter<{
        id: string | undefined;
        acc_holder: string | undefined;
        acc_number: string | undefined;
    }> = new EventEmitter();

    @Input() isTransactionDone = false;
    @Input() isGrey = false;
    @Input() title = 'Debit Account';
    @Input() creditAccountType = '';
    @Input() selectedInstitutionType = '';
    @Input() selectedInstitution: any;
    @Input() closedModal = false;
    @Input() isModalClosed = false;
    @Input() isWalletShown = true;
    @Input() isAccountShown = true;
    @Input() isTermDeposit = false;
    @Input() isOperation = false;
    @Input() isAmountChanging = false;

    lookup: any = new FormControl('');
    private onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private transferService: TransferService,
        private store: Store,
        private bankService: BankService,
        private clientService: ClientService
    ) {
        this.plateform$ = this.store.select(SwitchState.GetPlateform);
        this.bankId$ = this.store.select(BankState.GetSelectedClientBankId);
        this.selectedBank$ = this.store.select(BankState.GetSelectedClientBank);
        this.clientId$ = this.store.select(AuthState.GetClientId);
        this.theme$ = this.store.select(SwitchThemeState.GetTheme);
        this.isBalanceShown$ = this.store.select(AmountState.isShowed);
    }

    ngOnInit() {
        this.plateform$.pipe(takeUntil(this.onDestroy$)).subscribe({
            next: (plateform: any) => {
                this.plateform = plateform;
            },
        });

        if (!this.isWalletShown) {
            // this.selectDebitAccountType('account');
        }
        if (!this.isAccountShown) {
            // this.selectDebitAccountType('wallet');
        }

        this.isBalanceShown$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((isShowed: boolean) => {
                this.isBalanceShown = isShowed;
            });

        this.clientId$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((clientId: any) => {
                this.clientId = clientId;
                this.selectedBank$
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe((selectedBank: any) => {
                        this.selectedBank = selectedBank;
                        if (!this.isWalletShown) {
                            // this.selectDebitAccountType('account');
                        }
                        if (!this.isAccountShown) {
                            // this.selectDebitAccountType('wallet');
                        }
                    });
            });

        this.theme$.pipe(takeUntil(this.onDestroy$)).subscribe({
            next: (theme: string) => {
                this.theme = theme;
            },
        });
        this.bankId$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((bankId: any) => {
                this.bankId = bankId;
            });

        if (this.closedModal === true) {
            this.deselectDebitAccount();
        }
        this.getBanks();
    }

    ngDoCheck() {
        if (this.isTransactionDone) {
            this.updateAccount();
            // console.log('tttt debit-acc', this.isTransferDone);
        }
        // console.log('ttt', this.isTransferDone);

        if (this.isModalClosed) {
            const options = {
                account: null,
                wallet: null,
                selectedDebitOption: '',
                creditAccountType: null,
                isTransferDone: this.isTransactionDone,
                isAmountChanging: false,
                selectedInstitutionType: '',
                selectedInstitution: '',
            };

            this.debitOptions.emit(options);

            // this.isModalClosed = false;
        }
    }

    getAccountsListByClick() {
        const data = {
            client_id: this.clientId,
            access_bank_id: this.bankId,
        };
        this.transferService
            .getAccountsList()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((accounts: any) => {
                this.accounts = accounts.objects;

                //
            });
    }
    getBanks() {
        this.bankService.getBanksList().subscribe((banks: any) => {
            this.clientBanks = banks.objects;
        });
    }
    getAccountsListAutomatically() {
        this.isTransactionDone = false;
        const options = {
            account: this.debitAccount,
            wallet: null,
            selectedDebitOption: this.selectedDebitAccountType,
            creditAccountType: this.creditAccountType,
            isTransferDone: this.isTransactionDone,
            isAmountChanging: true,
            selectedInstitutionType: this.selectedInstitutionType,
            selectedInstitution: this.selectedInstitution,
        };

        this.debitOptions.emit(options);

        const data = {
            client_id: this.clientId,
            access_bank_id: this.bankId,
        };
        this.transferService
            .getAccountsList()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((accounts: any) => {
                this.accounts = accounts.objects;
                this.selectDebitAccount(this.index);
                this.isTransactionDone = false;
                this.isAmountChanging = false;
                this.bankService.updateTransaction(true);
                const options = {
                    account: this.debitAccount,
                    wallet: null,
                    selectedDebitOption: this.selectedDebitAccountType,
                    creditAccountType: this.creditAccountType,
                    isTransferDone: this.isTransactionDone,
                    isAmountChanging: false,
                    selectedInstitutionType: this.selectedInstitutionType,
                    selectedInstitution: this.selectedInstitution,
                };

                this.debitOptions.emit(options);

                //
            });
    }

    getWalletsListByClick() {
        this.transferService
            .getWalletsList(this.clientId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((wallets: any) => {
                this.wallets = wallets.objects;
                //
            });
    }

    getWalletsListAutomatically() {
        this.isTransactionDone = false;
        const options = {
            account: null,
            wallet: this.debitWallet,
            selectedDebitOption: this.selectedDebitAccountType,
            creditAccountType: this.creditAccountType,
            isTransferDone: this.isTransactionDone,
            isAmountChanging: true,
            selectedInstitutionType: this.selectedInstitutionType,
            selectedInstitution: this.selectedInstitution,
        };

        this.debitOptions.emit(options);

        this.transferService
            .getWalletsList(this.clientId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((wallets: any) => {
                this.wallets = wallets.objects;
                this.isAmountChanging = false;

                //

                this.selectDebitWallet(this.index);
                this.isTransactionDone = false;
                const options = {
                    account: null,
                    wallet: this.debitWallet,
                    selectedDebitOption: this.selectedDebitAccountType,
                    creditAccountType: this.creditAccountType,
                    isTransferDone: this.isTransactionDone,
                    isAmountChanging: false,
                    selectedInstitutionType: this.selectedInstitutionType,
                    selectedInstitution: this.selectedInstitution,
                };

                this.debitOptions.emit(options);
            });
    }
    selectBank(bank: any) {
        this.selectedBank = bank;

        this.store.dispatch(
            new SelectClientBank({
                id: this.selectedBank.id,
                name: this.selectedBank.name,
                slug: this.selectedBank.slug,
                bank_type: this.selectedBank.bank_type,
                bank_code: this.selectedBank.bank_code,
                is_active: this.selectedBank.is_active,
                is_default: this.selectedBank.is_default,
                company: this.selectedBank.company,
            })
        );
    }

    getClient(client: any) {
        // console.log('aaccc', client);

        // this.debitAccount={

        // }
        this.debitAccount = client;
        // const options = {
        //     account: this.debitAccount,
        //     wallet: null,
        //     selectedDebitOption: this.selectedDebitAccountType,
        //     creditAccountType: null,
        //     isTransferDone: this.isTransactionDone,
        //     isAmountChanging: false,
        //     selectedInstitutionType: this.selectedInstitutionType,
        //     selectedInstitution: this.selectedInstitution,
        // };

        const options = {
            id: this.debitAccount.id,
            acc_number: this.debitAccount.lookup_subtitle,
            acc_holder: this.debitAccount.lookup_title,
        };

        this.lookupOptions.emit(options);
        // this.debitOptions.emit(options);
        // console.log('debbbb', options);

        if (!this.debitAccount) {
            //
        }
    }

    getAmount(event: any) {
        // console.log(event);
    }

    selectDebitAccountType(accountType: string) {
        this.selectedDebitAccountType = accountType;
        this.walletName = undefined;
        this.walletNumber = undefined;
        const options = {
            account: null,
            wallet: null,
            selectedDebitOption: this.selectedDebitAccountType,
            creditAccountType: null,
            isTransferDone: this.isTransactionDone,
            isAmountChanging: false,
            selectedInstitutionType: this.selectedInstitutionType,
            selectedInstitution: this.selectedInstitution,
        };
        this.debitOptions.emit(options);
        this.deselectAccount.emit(true);

        if (accountType !== this.selectedDebitAccountType) {
            this.selectedDebitAccountType = '';
        }
        if (accountType == 'account') {
            // this.lookupType = 'lookup';
            if (this.plateform !== 'workStation') {
                this.getAccountsListByClick();
                this.lookupDebitAccountUrl =
                    '/clients/manage/accounts/object_lookup?lookup_data=';
            }

            this.debitAccount = undefined;
        } else if (accountType == 'wallet') {
            if (this.plateform !== 'workStation') {
                this.getWalletsListByClick();
            }
            this.lookupType = 'lookup';

            this.lookupDebitAccountUrl =
                '/dbs/wallets/object_lookup?lookup_data=';
            this.debitAccount = undefined;
        } else if (accountType == 'internal') {
            this.lookupType = 'autocomplete';
            this.lookupDebitAccountUrl = '/ledger/objects_autocomplete?search=';
            this.debitAccount = undefined;
        } else if (accountType == 'treasury') {
            this.lookupType = 'autocomplete';
            this.lookupDebitAccountUrl =
                '/treasury/institutions/manage/objects_autocomplete?search=';
            this.debitAccount = undefined;
        } else if (accountType == 'agent') {
            this.lookupType = 'lookup';
            this.lookupDebitAccountUrl =
                '/dbs/agents/object_lookup?lookup_data=';
            this.debitAccount = undefined;
        } else if (accountType == 'merchant') {
            this.lookupType = 'lookup';
            this.lookupDebitAccountUrl =
                '/dbs/merchant/manage/object_lookup?lookup_data=';
            this.debitAccount = undefined;
        }
    }

    deselectDebitAccount() {
        this.debitAccount = undefined;
        this.debitWallet = undefined;
        const options = {
            account: null,
            wallet: null,
            selectedDebitOption: this.selectedDebitAccountType,
            creditAccountType: null,
            isTransferDone: this.isTransactionDone,
            isAmountChanging: false,
            selectedInstitutionType: this.selectedInstitutionType,
            selectedInstitution: this.selectedInstitution,
        };

        this.debitOptions.emit(options);
    }

    selectDebitAccount(index: number) {
        this.debitAccount = this.accounts[index];
        this.index = index;
        const options = {
            account: this.debitAccount,
            wallet: null,
            selectedDebitOption: this.selectedDebitAccountType,
            creditAccountType: null,
            isTransferDone: this.isTransactionDone,
            isAmountChanging: false,
            selectedInstitutionType: this.selectedInstitutionType,
            selectedInstitution: this.selectedInstitution,
        };

        this.debitOptions.emit(options);
        // console.log('selectedDebitAccount' + index, this.debitAccount);
    }

    updateAccount() {
        const options = {
            account: this.debitAccount,
            wallet: this.debitWallet,
            selectedDebitOption: this.selectedDebitAccountType,
            creditAccountType: this.creditAccountType,
            isTransferDone: this.isTransactionDone,
            isAmountChanging: false,
            selectedInstitutionType: this.selectedInstitutionType,
            selectedInstitution: this.selectedInstitution,
        };

        this.debitOptions.emit(options);
        if (this.selectedDebitAccountType === 'account') {
            this.getAccountsListAutomatically();
        }
        if (this.selectedDebitAccountType === 'wallet') {
            this.getWalletsListAutomatically();
        }
    }

    selectDebitWallet(index: number) {
        this.debitWallet = this.wallets[index];
        this.index = index;
        const options = {
            account: null,
            wallet: this.debitWallet,
            selectedDebitOption: this.selectedDebitAccountType,
            creditAccountType: null,
            isTransferDone: this.isTransactionDone,
            isAmountChanging: false,
            selectedInstitutionType: this.selectedInstitutionType,
            selectedInstitution: this.selectedInstitution,
        };

        this.debitOptions.emit(options);
        // console.log('selectedDebitWallet' + index, this.debitWallet);
    }

    toggleBanksList() {
        this.isBanksListShown = !this.isBanksListShown;
    }

    switchBank(index: any) {
        this.selectedBank = this.banks[index];

        this.store.dispatch(
            new SelectClientBank({
                id: this.selectedBank.id,
                name: this.selectedBank.name,
                slug: this.selectedBank.slug,
                bank_type: this.selectedBank.bank_type,
                bank_code: this.selectedBank.bank_code,
                is_active: this.selectedBank.is_active,
                is_default: this.selectedBank.is_default,
                company: this.selectedBank.company,
            })
        );

        this.selectedDebitAccountType = '';
        this.debitAccount = undefined;
        this.debitWallet = undefined;
    }

    getSwitchBankOptions(event: any) {
        const options = {
            account: null,
            wallet: null,
            selectedDebitOption: '',
            creditAccountType: event.creditAccountType,
            isTransferDone: this.isTransactionDone,
            isAmountChanging: false,
            selectedInstitutionType: this.selectedInstitutionType,
            selectedInstitution: this.selectedInstitution,
        };

        this.debitOptions.emit(options);
        this.selectedDebitAccountType = event.selectedDebitAccountType;
        this.debitAccount = event.debitAccount;
        this.debitWallet = event.debitWallet;
        this.banks = event.banks;
        this.accounts = event.accounts;

        // console.log('evvvvv', event);
    }

    toggleBalance() {
        this.isBalanceShown = !this.isBalanceShown;

        this.store.dispatch(new displayAmount({ show: this.isBalanceShown }));

        // console.log('balanceShown', this.isBalanceShown);
    }

    getIndividualClient(event: any) {
        const options = {
            account: event,
            wallet: null,
            selectedDebitOption: this.selectedDebitAccountType,
            creditAccountType: event.creditAccountType,
            isTransferDone: this.isTransactionDone,
            isAmountChanging: false,
            selectedInstitutionType: this.selectedInstitutionType,
            selectedInstitution: this.selectedInstitution,
        };

        this.debitOptions.emit(options);
    }

    getAmountOptions(event: any) {
        // console.log('amount from ticketing', event);

        this.amount.emit(event);
    }

    lookupAccount() {
        this.walletSearching = true;

        const dataWallet = {
            account_number: this.lookupWallet.value,
            bank_slug: 'MF1-0001',
            account_type: 'wallet',
        };
        return this.transferService
            .lookupAccount(dataWallet)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (response: any) => {
                    this.walletSearching = false;
                    if (response.object.success === true) {
                        this.walletName = response.object.response_data.name;
                        this.walletNumber =
                            response.object.response_data.account_number;
                        const options = {
                            id: undefined,
                            acc_holder: this.walletName,
                            acc_number: this.walletNumber,
                        };
                        console.log('OPTIONS', options);
                        this.lookupOptions.emit(options);

                        // if (this.selectedInstitution) {
                        //     if (
                        //         this.selectedInstitution.api_values.has_lookup
                        //     ) {
                        //         this.transferForm.patchValue({
                        //             accountNumber:
                        //                 this.creditAccount.account_number,
                        //             accountHolder: this.creditAccount.name,
                        //         });
                        //     }
                        // }

                        // if (this.selectedCreditAccountType === 'wallet') {
                        //     this.transferForm.patchValue({
                        //         accountNumber:
                        //             this.creditAccount.account_number,
                        //         accountHolder: this.creditAccount.name,
                        //     });
                        // }
                        this.lookupWallet.reset();
                    }

                    // if (!this.selectedInstitution) {
                    //     this.selectedInstitution = this.defaultBank;
                    // }

                    if (response.object.success === false) {
                        const data = {
                            title: '',
                            type: 'failed',
                            message:
                                'could not find the wallet with number ' +
                                this.lookup.value +
                                ' in ' +
                                this.selectedInstitution.name,
                        };
                        this.store.dispatch(new OpenDialog(data));
                    }
                },
                error: (err: any) => {
                    this.walletSearching = false;
                },
            });
    }
    closeAccountIndividual(account: boolean) {
        if (account) {
            this.deselectAccount.emit(true);
        }
    }
    deselectCreditAccount() {
        this.walletName = undefined;
        this.walletNumber = undefined;
        this.deselectAccount.emit(true);
        // this.transferForm.patchValue({
        //     debit_description: '',
        //     merchant_reference: '',
        // });
    }
    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();

        const options = {
            account: null,
            wallet: null,
            selectedDebitOption: '',
            creditAccountType: null,
            isTransferDone: this.isTransactionDone,
            isAmountChanging: false,
            selectedInstitutionType: '',
            selectedInstitution: '',
        };

        this.debitOptions.emit(options);
    }
}
