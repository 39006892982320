<aside class="p-3 ps-md-0 ps-xl-3">
    <!-- home menus -->
    <section class="mt-2" *ngIf="plateform === 'home'">
        <a
            class="mb-4 d-flex align-items-center clickable rounded text-gray"
            [ngClass]="{
                'is-link': theme === 'light-mode',
                'is-link-dark': theme === 'dark-mode'
            }"
            (click)="hideAsideMenu()"
            routerLink="/h"
            [routerLinkActiveOptions]="{ exact: true }"
            routerLinkActive="text-dark"
        >
            <span
                class="magis-icon me-3 rounded-pill"
                [ngClass]="{
                    'icon-bg': theme === 'light-mode',
                    'level-1': theme === 'dark-mode'
                }"
                style="padding: 0.78rem 1rem"
                ><i class="fa-solid fa-house"></i
            ></span>
            <span class="fw-bold" i18n>Publications</span>
        </a>
        <a
            [ngClass]="{
                'is-link': theme === 'light-mode',
                'is-link-dark': theme === 'dark-mode'
            }"
            (click)="hideAsideMenu()"
            class="mb-4 d-flex align-items-center justify-content-between ckable rounded text-gray"
            routerLink="/h/home/chat"
            routerLinkActive="text-dark"
        >
            <span class="">
                <span
                    [ngClass]="{
                        'icon-bg': theme === 'light-mode',
                        'level-1': theme === 'dark-mode'
                    }"
                    class="info-icon me-3 d-inline-block rounded-pill"
                    style="padding: 0.7rem 0.9rem"
                    ><i class="fa-regular fa-comments"></i
                ></span>
                <span class="fw-bold" i18n>Chat</span>
            </span>
            <span
                style="padding: 0 0.6rem; font-size: 14px"
                class="me-xl-5 rounded text-blue fw-bold"
                [ngClass]="{
                    'level-2': theme === 'light-mode',
                    'level-1': theme === 'dark-mode'
                }"
            >
                0
            </span>
        </a>

        <a
            [ngClass]="{
                'is-link': theme === 'light-mode',
                'is-link-dark': theme === 'dark-mode'
            }"
            class="mt-4 d-flex align-items-center clickable rounded text-gray"
            routerLink="/m"
            routerLinkActive="text-dark"
            (click)="
                switchPlateform('market');
                toggleMarket('market');
                hideAsideMenu()
            "
        >
            <span
                [ngClass]="{
                    'icon-bg': theme === 'light-mode',
                    'level-1': theme === 'dark-mode'
                }"
                class="success-icon me-3 rounded-pill"
                style="padding: 0.78rem 1rem"
                ><i class="fa-solid fa-cart-shopping"></i
            ></span>
            <span class="fw-bold" i18n>Market</span>
        </a>

        <!-- <a
            class="mt-4 d-flex align-items-center clickable rounded text-gray"
            routerLink="/b/onlineBanking/banking/menuBanking/wallets"
            routerLinkActive="text-dark"
            [ngClass]="{
                'p-2 mb-1': plateform === 'home',
                'p-1 mb-4': plateform === 'onlineBanking',
                'is-link': theme === 'light-mode',
                'is-link-dark': theme === 'dark-mode'
            }"
            (click)="switchPlateform('onlineBanking'); hideAsideMenu()"
        >
            <span
                [ngClass]="{
                    'icon-bg': theme === 'light-mode',
                    'level-1': theme === 'dark-mode'
                }"
                class="fs-5 me-3 rounded-pill d-flex align-items-center justify-content-center text-primary"
                style="padding: 0.7rem 0.86rem"
            >
                <i class="fa-solid fa-wallet info-icon"></i>
            </span>
            <span class="fw-bold" i18n>Wallet</span>
        </a>

        <a
            class="mt-4 d-flex align-items-center clickable rounded text-gray"
            [ngClass]="{
                'is-link': theme === 'light-mode',
                'is-link-dark': theme === 'dark-mode'
            }"
            (click)="hideAsideMenu()"
            [routerLinkActiveOptions]="{ exact: true }"
            routerLinkActive="text-dark"
        >
            <span
                class="magis-icon me-3 rounded-pill"
                [ngClass]="{
                    'icon-bg': theme === 'light-mode',
                    'level-1': theme === 'dark-mode'
                }"
                style="padding: 0.78rem 1rem"
            >
                <i class="fa-solid fa-paper-plane text-secondary"></i>
            </span>
            <div class="d-flex justify-content-between gap-5">
                <span class="fw-bold" i18n> Wallet Transfers</span>
            </div>
        </a>

        <a
            class="mt-4 d-flex align-items-center clickable rounded text-gray"
            [ngClass]="{
                'is-link': theme === 'light-mode',
                'is-link-dark': theme === 'dark-mode'
            }"
            (click)="hideAsideMenu()"
            routerLink="/b/onlineBanking/withdrawal"
            [routerLinkActiveOptions]="{ exact: true }"
            routerLinkActive="text-dark"
        >
            <span
                class="magis-icon me-3 rounded-pill"
                [ngClass]="{
                    'icon-bg': theme === 'light-mode',
                    'level-1': theme === 'dark-mode'
                }"
                style="padding: 0.78rem 1rem"
            >
                <img
                    src="../assets/images/withdrawal.svg"
                    class="img-size text-primary"
                />
            </span>
            <span class="fw-bold" i18n> Wallet Withdrawal</span>
        </a>

        <a
            class="mt-4 d-flex align-items-center clickable rounded text-gray"
            [ngClass]="{
                'is-link': theme === 'light-mode',
                'is-link-dark': theme === 'dark-mode'
            }"
            (click)="hideAsideMenu()"
            [routerLinkActiveOptions]="{ exact: true }"
            routerLinkActive="text-dark"
        >
            <span
                class="magis-icon me-3 rounded-pill"
                [ngClass]="{
                    'icon-bg': theme === 'light-mode',
                    'level-1': theme === 'dark-mode'
                }"
                style="padding: 0.78rem 1rem"
                ><i class="fa-solid fa-clock text-secondary"></i
            ></span>
            <span class="fw-bold" i18n>Wallet Statements</span>
        </a> -->
    </section>

    <!-- onlineBanking menus -->

    <section *ngIf="plateform === 'onlineBanking'">
        <div>
            <a
                class="mb-4 d-flex align-items-center clickable rounded text-gray"
                [ngClass]="{
                    'is-link': theme === 'light-mode',
                    'is-link-dark': theme === 'dark-mode'
                }"
                (click)="hideAsideMenu()"
                routerLink="/b"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="text-dark"
            >
                <span
                    class="magis-icon me-3 rounded-pill"
                    [ngClass]="{
                        'icon-bg': theme === 'light-mode',
                        'level-1': theme === 'dark-mode'
                    }"
                    style="padding: 0.78rem 1rem"
                    ><i class="fa-solid fa-house"></i
                ></span>
                <span class="fw-bold" i18n>Home</span>
            </a>
            <a
                class="d-flex align-items-center clickable rounded text-gray"
                routerLink="/b/onlineBanking/tarifs"
                routerLinkActive="text-dark"
                [ngClass]="{
                    'p-2 mb-1': plateform === 'home',
                    'p-1 mb-4': plateform === 'onlineBanking',
                    'is-link': theme === 'light-mode',
                    'is-link-dark': theme === 'dark-mode'
                }"
                (click)="switchPlateform('onlineBanking'); hideAsideMenu()"
            >
                <span
                    [ngClass]="{
                        'icon-bg': theme === 'light-mode',
                        'level-1': theme === 'dark-mode'
                    }"
                    class="fs-5 me-3 rounded-pill d-flex align-items-center justify-content-center text-primary"
                    style="padding: 0.7rem 0.86rem"
                >
                    <i class="fa-solid fa-calculator"></i>
                </span>
                <span class="fw-bold" i18n>Tarifs</span>
            </a>

            <!-- <a
                class="mb-4 d-flex align-items-center clickable rounded text-gray"
                [ngClass]="{
                    'is-link': theme === 'light-mode',
                    'is-link-dark': theme === 'dark-mode'
                }"
                (click)="hideAsideMenu()"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="text-dark"
            >
                <span
                    class="magis-icon me-3 rounded-pill"
                    [ngClass]="{
                        'icon-bg': theme === 'light-mode',
                        'level-1': theme === 'dark-mode'
                    }"
                    style="padding: 0.78rem 1rem"
                >
                    <i class="fa-solid fa-paper-plane text-primary"></i>
                </span>
                <div class="d-flex justify-content-between gap-5">
                    <span class="fw-bold" i18n> Wallet Transfers</span>

                </div>
            </a>

            <a
                class="mb-4 d-flex align-items-center clickable rounded text-gray"
                [ngClass]="{
                    'is-link': theme === 'light-mode',
                    'is-link-dark': theme === 'dark-mode'
                }"
                (click)="hideAsideMenu()"
                routerLink="/b/onlineBanking/withdrawal"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="text-dark"
            >
                <span
                    class="magis-icon me-3 rounded-pill"
                    [ngClass]="{
                        'icon-bg': theme === 'light-mode',
                        'level-1': theme === 'dark-mode'
                    }"
                    style="padding: 0.78rem 1rem"
                >
                    <img
                        src="../assets/images/withdrawal.svg"
                        class="img-size text-primary"
                    />
                </span>
                <span class="fw-bold" i18n> Wallet Withdrawal</span>
            </a>

            <a
                class="mb-4 d-flex align-items-center clickable rounded text-gray"
                [ngClass]="{
                    'is-link': theme === 'light-mode',
                    'is-link-dark': theme === 'dark-mode'
                }"
                (click)="hideAsideMenu()"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="text-dark"
            >
                <span
                    class="magis-icon me-3 rounded-pill"
                    [ngClass]="{
                        'icon-bg': theme === 'light-mode',
                        'level-1': theme === 'dark-mode'
                    }"
                    style="padding: 0.78rem 1rem"
                    ><i class="fa-solid fa-clock text-secondary"></i
                ></span>
                <span class="fw-bold" i18n>Wallet Statements</span>
            </a> -->
        </div>
        <!-- <div *ngIf="selectedBank.id">
            <div class="mb-4">
                <div *ngIf="selectedBank">
                    <div
                        class="mb-4 d-flex align-items-center sm-radius border p-2 px-3"
                    >
                        <div style="height: 50px">
                            <img
                                src="{{ selectedBank.company.logo }}"
                                alt=""
                                class="image"
                            />
                        </div>
                        <div class="d-flex flex-column ms-3">
                            <b class="text-xsm">{{
                                selectedBank.name ?? '------'
                            }}</b>
                            <small class="text-dark text-xsm">{{
                                selectedBank.bank_type ?? '------'
                            }}</small>
                        </div>
                    </div>

                    <a
                        class="mb-4 d-flex align-items-center clickable rounded text-gray"
                        [ngClass]="{
                            'is-link': theme === 'light-mode',
                            'is-link-dark': theme === 'dark-mode'
                        }"
                        (click)="hideAsideMenu()"
                        routerLink="/b/onlineBanking/banking/menuBanking/accounts"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLinkActive="text-dark"
                    >
                        <span
                            class="magis-icon me-3 rounded-pill"
                            [ngClass]="{
                                'icon-bg': theme === 'light-mode',
                                'level-1': theme === 'dark-mode'
                            }"
                            style="padding: 0.78rem 1rem"
                            ><i class="fa-solid fa-circle-user"></i
                        ></span>
                        <span class="fw-bold" i18n>Accounts</span>
                    </a>

                    <a
                        class="mb-4 d-flex align-items-center clickable rounded text-gray"
                        [ngClass]="{
                            'is-link': theme === 'light-mode',
                            'is-link-dark': theme === 'dark-mode'
                        }"
                        (click)="hideAsideMenu()"
                        routerLink="/b/onlineBanking/banking/menuBanking/saving"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLinkActive="text-dark"
                    >
                        <span
                            class="magis-icon me-3 rounded-pill"
                            [ngClass]="{
                                'icon-bg': theme === 'light-mode',
                                'level-1': theme === 'dark-mode'
                            }"
                            style="padding: 0.78rem 1rem"
                            ><i class="fa-solid fa-piggy-bank text-secondary">
                            </i
                        ></span>
                        <span class="fw-bold" i18n>Savings</span>
                    </a>

                    <a
                        class="mb-4 d-flex align-items-center clickable rounded text-gray"
                        [ngClass]="{
                            'is-link': theme === 'light-mode',
                            'is-link-dark': theme === 'dark-mode'
                        }"
                        (click)="hideAsideMenu()"
                        routerLink="/b/onlineBanking/banking/menuBanking/loan"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLinkActive="text-dark"
                    >
                        <span
                            class="magis-icon me-3 rounded-pill"
                            [ngClass]="{
                                'icon-bg': theme === 'light-mode',
                                'level-1': theme === 'dark-mode'
                            }"
                            style="padding: 0.78rem 1rem"
                            ><img
                                src="../assets/images/loans-success.svg"
                                class="img-size"
                        /></span>
                        <span class="fw-bold" i18n>Loans</span>
                    </a>

                    <a
                        class="mb-4 d-flex align-items-center clickable rounded text-gray"
                        [ngClass]="{
                            'is-link': theme === 'light-mode',
                            'is-link-dark': theme === 'dark-mode'
                        }"
                        (click)="hideAsideMenu()"
                        routerLink="/b/onlineBanking/withdrawal"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLinkActive="text-dark"
                    >
                        <span
                            class="magis-icon me-3 rounded-pill"
                            [ngClass]="{
                                'icon-bg': theme === 'light-mode',
                                'level-1': theme === 'dark-mode'
                            }"
                            style="padding: 0.78rem 1rem"
                        >
                            <img
                                src="../assets/images/withdrawal-blue.svg"
                                class="img-size"
                            />
                        </span>
                        <span class="fw-bold" i18n>Withdrawals</span>
                    </a>

                    <a
                        class="mb-4 d-flex align-items-center clickable rounded text-gray"
                        [ngClass]="{
                            'is-link': theme === 'light-mode',
                            'is-link-dark': theme === 'dark-mode'
                        }"
                        (click)="hideAsideMenu()"
                        routerLink="/b/onlineBanking/banking/menuBanking/transfer"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLinkActive="text-dark"
                    >
                        <span
                            class="magis-icon me-3 rounded-pill"
                            [ngClass]="{
                                'icon-bg': theme === 'light-mode',
                                'level-1': theme === 'dark-mode'
                            }"
                            style="padding: 0.78rem 1rem"
                        >
                            <i class="fa-solid fa-paper-plane text-primary"></i>
                        </span>
                        <div class="d-flex justify-content-between gap-5">
                            <span class="fw-bold" i18n>Transfers</span>
                            <span class="ml-3">
                                <span
                                    class="rounded px-2 d-flex justify-content-center text-primary fw-bold"
                                    [ngClass]="{
                                        'icon-bg': theme === 'light-mode',
                                        'level-1': theme === 'dark-mode'
                                    }"
                                >
                                    {{ transfersLenght }}</span
                                >
                            </span>
                        </div>
                    </a>

                    <a
                        class="mb-4 d-flex align-items-center clickable rounded text-gray"
                        [ngClass]="{
                            'is-link': theme === 'light-mode',
                            'is-link-dark': theme === 'dark-mode'
                        }"
                        (click)="hideAsideMenu()"
                        routerLink="/b/onlineBanking/banking/menuBanking/general-statement/transactions"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLinkActive="text-dark"
                    >
                        <span
                            class="magis-icon me-3 rounded-pill"
                            [ngClass]="{
                                'icon-bg': theme === 'light-mode',
                                'level-1': theme === 'dark-mode'
                            }"
                            style="padding: 0.78rem 1rem"
                            ><i class="fa-solid fa-clock text-secondary"></i
                        ></span>
                        <span class="fw-bold" i18n> Statements</span>
                    </a>
                </div>
            </div>
        </div> -->
    </section>

    <!-- market and myMarket menus -->
    <section class="ms-2">
        <section *ngIf="plateform === 'market'">
            <div *ngIf="activeMarket === 'myMarket'">
                <a
                    (click)="hideAsideMenu()"
                    [ngClass]="{
                        'is-link': theme === 'light-mode',
                        'is-link-dark': theme === 'dark-mode'
                    }"
                    class="mb-4 d-flex align-items-center clickable rounded text-gray"
                    routerLink="/m/market/mymarket"
                    [routerLinkActiveOptions]="{ exact: true }"
                    routerLinkActive="text-dark"
                >
                    <span
                        [ngClass]="{
                            'icon-bg': theme === 'light-mode',
                            'level-1': theme === 'dark-mode'
                        }"
                        class="magis-icon me-3 rounded-pill"
                        style="padding: 0.68rem 0.9rem"
                        ><i class="fa-solid fa-house"></i
                    ></span>
                    <span class="fw-bold" i18n>Home</span>
                </a>
                <a
                    (click)="hideAsideMenu()"
                    [ngClass]="{
                        'is-link': theme === 'light-mode',
                        'is-link-dark': theme === 'dark-mode'
                    }"
                    class="mb-4 d-flex align-items-center justify-content-between clickable rounded text-gray"
                    routerLinkActive="text-dark"
                >
                    <span class="">
                        <span
                            [ngClass]="{
                                'icon-bg': theme === 'light-mode',
                                'level-1': theme === 'dark-mode'
                            }"
                            class="text-primary me-3 d-inline-block rounded-pill"
                            style="padding: 0.6rem 0.83rem"
                        >
                            <i class="fa-solid fa-store"></i>
                        </span>
                        <span class="fw-bold" i18n>Orders</span>
                    </span>
                    <span
                        style="padding: 0 0.6rem; font-size: 14px"
                        class="me-xxl-5 rounded text-primary fw-bold"
                        [ngClass]="{
                            'level-2': theme === 'light-mode',
                            'level-1': theme === 'dark-mode'
                        }"
                    >
                        0
                    </span>
                </a>
            </div>

            <div *ngIf="activeMarket === 'market'">
                <a
                    [ngClass]="{
                        'is-link': theme === 'light-mode',
                        'is-link-dark': theme === 'dark-mode'
                    }"
                    class="mb-4 d-flex align-items-center clickable rounded text-gray"
                    routerLink="/m"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="switchPlateform('market'); hideAsideMenu()"
                    routerLinkActive="text-dark"
                >
                    <span
                        [ngClass]="{
                            'icon-bg': theme === 'light-mode',
                            'level-1': theme === 'dark-mode'
                        }"
                        class="magis-icon me-3 rounded-pill"
                        style="padding: 0.78rem 1rem"
                        ><i class="fa-solid fa-house"></i
                    ></span>
                    <span class="fw-bold" i18n>Home</span>
                </a>
                <a
                    (click)="hideAsideMenu()"
                    [ngClass]="{
                        'is-link': theme === 'light-mode',
                        'is-link-dark': theme === 'dark-mode'
                    }"
                    class="mb-4 d-flex align-items-center clickable rounded text-gray"
                    [routerLinkActiveOptions]="{ exact: true }"
                    routerLink="/m/market/products"
                    routerLinkActive="text-dark"
                >
                    <span
                        [ngClass]="{
                            'icon-bg': theme === 'light-mode',

                            'level-1': theme === 'dark-mode'
                        }"
                        class="success-icon me-3 rounded-pill"
                        style="padding: 0.69rem 0.9rem"
                        ><i class="fa-solid fa-basket-shopping"></i
                    ></span>

                    <span class="fw-bold" i18n>Products</span>
                </a>
                <a
                    (click)="hideAsideMenu()"
                    [ngClass]="{
                        'is-link': theme === 'light-mode',
                        'is-link-dark': theme === 'dark-mode'
                    }"
                    class="mb-4 d-flex align-items-center clickable rounded text-gray"
                    [routerLinkActiveOptions]="{ exact: true }"
                    routerLinkActive="text-dark"
                    routerLink="/m/market/merchants"
                >
                    <span
                        [ngClass]="{
                            'icon-bg': theme === 'light-mode',
                            'level-1': theme === 'dark-mode'
                        }"
                        class="magis-icon me-3 rounded-pill"
                        style="padding: 0.68rem 0.87rem"
                        ><i class="fa-solid fa-shop"></i
                    ></span>
                    <span class="fw-bold" i18n>Merchants</span>
                </a>
            </div>

            <a
                (click)="hideAsideMenu()"
                [ngClass]="{
                    'is-link': theme === 'light-mode',
                    'is-link-dark': theme === 'dark-mode'
                }"
                class="mb-4 d-flex align-items-center justify-content-between clickable rounded text-gray"
                routerLink="/m/market/carts"
                routerLinkActive="text-dark"
            >
                <span class="">
                    <span
                        [ngClass]="{
                            'icon-bg': theme === 'light-mode',
                            'level-1': theme === 'dark-mode'
                        }"
                        class="success-icon me-3 d-inline-block rounded-pill"
                        style="padding: 0.6rem 0.8rem"
                    >
                        <span *ngIf="activeMarket === 'myMarket'">
                            <i class="fa-solid fa-cart-arrow-down"></i>
                        </span>
                        <span *ngIf="activeMarket === 'market'">
                            <i class="fa-solid fa-cart-shopping"></i>
                        </span>
                    </span>
                    <span class="fw-bold" i18n>Carts</span>
                </span>
                <span
                    style="padding: 0 0.6rem; font-size: 14px"
                    class="me-xxl-5 rounded success-badge fw-bold"
                    [ngClass]="{
                        'level-2': theme === 'light-mode',
                        'level-1': theme === 'dark-mode'
                    }"
                >
                    0
                </span>
            </a>
            <a
                *ngIf="plateform === 'market'"
                class="d-flex align-items-center clickable rounded text-gray"
                [ngClass]="{
                    'p-2 mb-1': plateform === 'home',
                    'p-1 mb-4': plateform === 'onlineBanking',
                    'is-link': theme === 'light-mode',
                    'is-link-dark': theme === 'dark-mode',
                    'mb-4': plateform === 'market'
                }"
                routerLink="/m/market/bills"
                routerLinkActive="text-dark"
                (click)="switchPlateform('market'); hideAsideMenu()"
            >
                <span
                    class="d-flex align-items-center justify-content-between w-100"
                    *ngIf="plateform === 'market'"
                >
                    <span class="d-flex align-items-center">
                        <span
                            [ngClass]="{
                                'icon-bg': theme === 'light-mode',
                                'level-1': theme === 'dark-mode'
                            }"
                            class="magis-icon me-3 fs-5 d-inline-block rounded-pill"
                            style="padding: 0.5rem 1rem"
                        >
                            <i class="fa-solid fa-file-invoice-dollar"></i>
                        </span>
                        <span class="fw-bold d-block" i18n>
                            <b *ngIf="activeMarket !== 'myMarket'">Bills</b>
                            <b *ngIf="activeMarket === 'myMarket'"
                                >Payments Report</b
                            >
                        </span>
                    </span>
                    <span
                        style="padding: 0 0.6rem; font-size: 14px"
                        class="me-xxl-5 rounded text-blue fw-bold"
                        [ngClass]="{
                            'level-2': theme === 'light-mode',
                            'level-1': theme === 'dark-mode'
                        }"
                    >
                        <span *ngIf="activeMarket !== 'myMarket'">{{
                            billsCount ?? ''
                        }}</span>
                        <span *ngIf="activeMarket === 'myMarket'">{{
                            billsReportCount
                        }}</span>
                    </span>
                </span>
            </a>

            <a
                *ngIf="
                    plateform === 'market' &&
                    activeMarket === 'market' &&
                    isMerchant
                "
                class="mb-4 d-flex align-items-center clickable rounded sm-radius col-11"
                style="border: 1px solid hsla(0, 0%, 51%, 1)"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="text-dark"
                (click)="
                    switchPlateform('market');
                    toggleMarket('myMarket');
                    hideAsideMenu()
                "
                routerLink="/m/market/mymarket"
            >
                <span
                    class="me-3 d-inline-block rounded-pill"
                    style="
                        padding: 0.4rem 0.9rem;
                        font-size: 1.7rem;
                        padding-top: 0.8rem;
                        color: rgba(80, 12, 12, 1);
                    "
                >
                    <i class="fa-solid fa-store"></i>
                </span>
                <b class="text-gray" style="font-size: 24px" i18n>My market</b>
            </a>
            <div *ngIf="activeMarket === 'myMarket'">
                <a
                    (click)="hideAsideMenu()"
                    [ngClass]="{
                        'is-link': theme === 'light-mode',
                        'is-link-dark': theme === 'dark-mode'
                    }"
                    class="mb-4 d-flex clickable rounded text-gray justify-content-between w-100"
                    [routerLinkActiveOptions]="{ exact: true }"
                    routerLinkActive="text-dark"
                    routerLink="/m/market/payment-reports"
                >
                    <span
                        class="d-flex align-items-center justify-content-between w-100"
                        *ngIf="plateform === 'market'"
                    >
                        <span class="d-flex align-items-center">
                            <span
                                [ngClass]="{
                                    'icon-bg': theme === 'light-mode',
                                    'level-1': theme === 'dark-mode'
                                }"
                                class="me-3 d-inline-block rounded-pill text-blue"
                                style="padding: 0.48rem 0.92rem"
                            >
                                <i class="fa-solid fa-receipt"></i>
                            </span>
                            <span class="fw-bold d-block" i18n>
                                <b>Transfers Report</b>
                            </span>
                        </span>
                        <span
                            style="padding: 0 0.6rem; font-size: 14px"
                            class="me-xxl-5 rounded text-blue fw-bold"
                            [ngClass]="{
                                'level-2': theme === 'light-mode',
                                'level-1': theme === 'dark-mode'
                            }"
                        >
                            <span *ngIf="activeMarket === 'myMarket'">{{
                                paymentReportCount
                            }}</span>
                        </span>
                    </span>
                </a>
            </div>
            <div *ngIf="activeMarket === 'myMarket'">
                <a
                    style="border: 1px solid #828282"
                    class="mb-4 d-flex align-items-center clickable rounded col-11 col-md-12 col-xxl-11 flex-wrap"
                    (click)="toggleMarket('market'); hideAsideMenu()"
                    routerLink="/m"
                    [routerLinkActiveOptions]="{ exact: true }"
                    routerLinkActive="text-dark"
                >
                    <span
                        class="me-3 d-inline-block rounded-pill"
                        style="
                            padding: 0.3rem 0.9rem;
                            font-size: 1.7rem;
                            padding-top: 0.8rem;
                            color: hsla(340, 2%, 25%, 1);
                        "
                    >
                        <i class="text-dark fa-solid fa-cart-shopping"></i>
                    </span>

                    <b class="text-gray" style="font-size: 24px" i18n
                        >Market Place</b
                    >
                </a>
            </div>
        </section>
    </section>

    <div *ngIf="plateform === 'market'">
        <hr class="level-2 text-dark" style="margin-bottom: 1.3rem" />
        <section>
            <div class="text-center fw-bold w-50 category-title text-dark" i18n>
                Category
            </div>
            <div
                class="mt-4 text-center w-50 text-gray d-flex align-items-center justify-content-center flex-wrap"
            >
                <span class="icon me-2 fs-4"
                    ><i class="fa-regular fa-face-meh"></i
                ></span>
                <span i18n>No Products Category</span>
            </div>
        </section>
    </div>
</aside>
