import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';

import { Observable, Subject, takeUntil } from 'rxjs';
import {
    Switch,
    SwitchState,
    SwitchThemeState,
    BankState,
    AuthState,
    SelectClientBank,
    MenuState,
    SelectMarket,
} from '../../../../';
import {
    LoanService,
    TransferService,
    BankService,
    MarketService,
    ClientService,
    MenuService,
} from '../../../../../core';

@Component({
    selector: 'app-aside-menu',
    templateUrl: './aside-menu.component.html',
    styleUrls: ['./aside-menu.component.scss'],
})
export class AsideMenuComponent implements OnInit {
    showSide = false;
    plateform$: Observable<any>;
    plateform: any;
    theme$: Observable<any>;
    theme: any;
    loansLength = 0;
    transfersLenght = 0;
    private onDestroy$: Subject<void> = new Subject<void>();
    selectedBank: any;
    selectedBank$: Observable<any>;
    loans: any;
    accounts: any;
    clientInfo$: Observable<any>;
    isMerchant = false;
    billsCount: any;
    billsReportCount = '';
    paymentReportCount = '';

    clientId = '';
    banks: any;
    defaultBank: any;
    bankId$: Observable<any>;
    clientId$: Observable<any>;
    selectedStatement = '';
    activeMarkte$: Observable<any>;
    activeMarket = 'market';

    // homeMenus = [
    //     {
    //         type: 'primary',
    //         label: {
    //             name: 'Publications',
    //             class: '',
    //         },
    //         link: '/h',
    //         icon: {
    //             name: 'fa-solid fa-house',
    //             class: 'magis-icon',
    //             badge: {
    //                 content: '0',
    //                 color: '',
    //             },
    //         },
    //     },
    //     {
    //         type: 'primary',
    //         label: {
    //             name: 'Chat',
    //             class: '',
    //         },
    //         link: '/h/home/chat',
    //         icon: {
    //             name: 'fa-regular fa-comments',
    //             class: 'info-icon',
    //         },
    //         badge: {
    //             content: '3',
    //             color: 'color: #2070b8;',
    //         },
    //     },
    // ];

    menus = [];

    constructor(
        private store: Store,
        private loanService: LoanService,
        private transferService: TransferService,
        private marketService: MarketService,
        private bankService: BankService,
        private clientService: ClientService,
        private menuService: MenuService
    ) {
        this.plateform$ = this.store.select(SwitchState.GetPlateform);
        this.theme$ = this.store.select(SwitchThemeState.GetTheme);
        this.selectedBank$ = this.store.select(BankState.GetSelectedClientBank);
        this.clientInfo$ = this.store.select(AuthState.GetClientInfo);
        this.activeMarkte$ = this.store.select(MenuState.GetSelectedMarket);
        this.bankId$ = this.store.select(BankState.GetSelectedClientBankId);
        this.clientId$ = this.store.select(AuthState.GetClientId);
    }

    ngOnInit(): void {
        this.plateform$.subscribe({
            next: (plateform) => {
                this.plateform = plateform;
                // if (plateform === 'home' || plateform === 'market') {
                //     this.getBillsCount();
                // }
            },
        });

        this.selectedBank$.subscribe({
            next: (selectedBank) => {
                this.selectedBank = selectedBank;
            },
        });

        this.clientInfo$.subscribe({
            next: (response) => {
                if (response && response.isMerchant) {
                    this.isMerchant = response.isMerchant;
                }
            },
        });
        this.theme$.subscribe({
            next: (theme) => {
                this.theme = theme;
            },
        });
        this.activeMarkte$.subscribe({
            next: (market) => {
                this.activeMarket = market;
            },
        });

        // this.clientId$.subscribe({
        //     next: (clientId) => {
        //         if (clientId) {
        //             this.bankId$.subscribe({
        //                 next: (bankId) => {
        //                     if (bankId) {
        //                         this.getBillsReportCount();
        //                         this.getPaymentReportCount();
        //                     }
        //                 },
        //             });
        //         }
        //     },
        // });
        this.getItemsMenusLenght();
    }

    getItemsMenusLenght() {
        if (this.plateform === 'home' || this.plateform === 'onlineBanking') {
            this.bankService
                .getBanksList()
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((banks: any) => {
                    this.banks = banks.objects;
                    this.defaultBank = banks.objects.find(
                        (bank: any) => bank.is_default === true
                    );
                    this.selectedBank = this.defaultBank;

                    //console.log('ssss', this.selectedBank);
                    this.loanService
                        .getLoansList()
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe((loans: any) => {
                            this.loans = loans.object.response_data;

                            //console.log('loans', this.loans.length);
                        });

                    this.transferService
                        .getTransfersList()
                        .subscribe((response: any) => {
                            //console.log('transferService', response);
                            this.transfersLenght = response.count;
                        });
                });

            this.transferService
                .getTransfersList()
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((response: any) => {
                    this.transfersLenght = response.count;

                    this.transferService.getTransfersLength(
                        this.transfersLenght
                    );
                });

            this.loanService.loansLength$
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((loansLength: number) => {
                    this.loansLength = loansLength;
                    //console.log('lllll', loansLength);
                });

            this.transferService.transfersLength$
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((transferLength: number) => {
                    this.transfersLenght = transferLength;

                    // console.log('transferLengghgggggggggth', transferLength);
                });
        }
    }

    ngDoCheck() {
        this.loanService.loansLength$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((loansLength: number) => {
                this.loansLength = loansLength;
                //console.log('loansLength',loansLength)
            });

        this.transferService.transfersLength$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((transferLength: number) => {
                this.transfersLenght = transferLength;
                //console.log('transfersLenght',transferLength)
            });
    }

    /* the below function switch platforms according to the plateform name received as argument,
        arguments are either home, onlineBanking, market. */

    switchPlateform(plateform = '') {
        if (plateform) {
            this.store.dispatch(new Switch({ plateform: plateform }));
        }
    }

    toggleMarket(name: any) {
        this.store.dispatch(new SelectMarket({ marketName: name }));
    }
    hideAsideMenu() {
        this.menuService.toggleAsideMenu(true);
    }

    selectBank(bank: any) {
        this.selectedBank = bank;

        this.store.dispatch(
            new SelectClientBank({
                id: this.selectedBank.id,
                name: this.selectedBank.name,
                slug: this.selectedBank.slug,
                bank_type: this.selectedBank.bank_type,
                bank_code: this.selectedBank.bank_code,
                is_active: this.selectedBank.is_active,
                is_default: this.selectedBank.is_default,
                company: this.selectedBank.company,
            })
        );
    }

    selectStatement(statement: any) {
        this.selectedStatement = statement;
    }
}
