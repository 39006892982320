<div
    class="w-100"
    style="height: 50px; z-index: 100; top: 0"
    [ngClass]="{
        'header-color': plateform === 'workStation',
        'bg-primary': plateform !== 'workStation' && plateform !== 'onamob',
        'primary-onamob': plateform === 'onamob',

    }"
>
    <!-- ** Header global container, it has three sections one on left other on center and other on right**-->
    <div
        class="d-flex justify-content-center justify-content-lg-between w-100 h-100"
    >
        <!--* Left side section that contains the photo of the connected organization when we are in workstation and contains the photo of connected client when we are in online banking-->
        <div>
            <!--* The photo that displayed when we are in online banking-->
            <div
                class="d-none d-lg-block"
                *ngIf="plateform !== 'workStation'"
                style="width: 150px"
            >
                <div class="level-2 ps-1">
                    <img
                        class="img-fluid"
                        src="{{ onlineBankingHeaderImage }}"
                        style="height: 50px !important"
                    />
                </div>
            </div>

            <!--* The photo that displayed when we are in workstation-->
            <div
                *ngIf="organization && plateform === 'workStation'"
                style="
                    flex-direction: column;
                    justify-content: center;
                    margin-top: 0px;
                "
                class="p-1 d-none d-lg-flex"
            >
                <div
                    (click)="displayCorporatesSection()"
                    class="d-flex connectededOrganization rounded align-items-start ps-1"
                    style="width: 260px; height: 41px"
                    [ngClass]="{
                        'border-light':
                            showCorporatesSection && plateform === 'workStation'
                    }"
                    role="button"
                    *ngIf="plateform === 'workStation'"
                >
                    <div
                        style="width: 100px; height: 100%"
                        class="d-flex justify-content-start"
                    >
                        <img
                            [src]="organization.institution_client.picture"
                            class="img-fluid float-start w-100"
                            style="height: 41px"
                        />
                    </div>
                    <div
                        style="
                            margin-top: auto;
                            margin-bottom: auto;
                            line-height: 15px;
                            width: 100%;
                        "
                        class="ps-1"
                    >
                        <b class="text-dark">{{
                            organization.institution_client.client_full_name
                        }}</b
                        ><br />
                        <span style="font-size: 14px" class="text-dark">{{
                            organization.company_type_code
                        }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="d-none d-lg-block"
            [ngClass]="{
                'mx-auto': plateform === 'workStation'
            }"
        >
            <app-switch-plateform-icons
                [organization]="organization"
            ></app-switch-plateform-icons>
        </div>
        <!--* Right side section that contains menu icons for workstation -->
        <div
            class="d-flex align-items-center justify-content-around justify-content-lg-center position-relative"
            [ngClass]="{
                'bg-primary': plateform !== 'workStation'  && plateform !== 'onamob',
                'header-color': plateform === 'workStation',
                'primary-onamob': plateform === 'onamob',

            }"
        >
            <!-- Active mobile menus -->
            <span
                *ngIf="plateform !== 'workStation'"
                class="d-lg-none me-md-1"
                role="button"
                (click)="toggleAsideMenu()"
                [ngClass]="{
                    'text-primary-light':
                        theme === 'magis-light' || theme === 'magis-dark',
                    white: theme === 'light-mode' || theme === 'dark-mode'
                }"
            >
                <i class="fs-5 fa-solid fa-bars"></i>
            </span>

            <span
                role="button"
                class="ms-4 ms-md-5 ms-lg-2 p-2 me-md-1"
                [ngClass]="{
                    'text-primary-light':
                        theme === 'magis-light' || theme === 'magis-dark',
                    white: theme === 'light-mode' || theme === 'dark-mode'
                }"
            >
                <span
                    title="ctrl + shift + v to toggle Amount View"
                    *ngIf="!eyeShowed"
                    (click)="toggleEyeStatus()"
                >
                    <i class="fa-solid fa-eye"></i>
                </span>
                <span
                    title="ctrl + shift + v to toggle Amount View"
                    *ngIf="eyeShowed"
                    (click)="toggleEyeStatus()"
                >
                    <i class="fa-solid fa-eye-slash"></i>
                </span>
            </span>
            <span
                class="ms-4 ms-md-5 ms-lg-2 p-2 me-md-1"
                role="button"
                (click)="toggleAsideMenu(true); toggleNotFoundPopup('chat')"
                [ngClass]="{
                    'text-primary-light':
                        theme === 'magis-light' || theme === 'magis-dark',
                    white: theme === 'light-mode' || theme === 'dark-mode'
                }"
            >
                <i
                    class="fa-regular fa-comments fs-6"
                    (click)="showMenu = true"
                ></i>
            </span>
            <span
                class="ms-4 ms-md-5 ms-lg-2 p-2 me-2"
                role="button"
                (click)="
                    toggleAsideMenu(true); toggleNotFoundPopup('notification')
                "
                [ngClass]="{
                    'text-primary-light':
                        theme === 'magis-light' || theme === 'magis-dark',
                    white: theme === 'light-mode' || theme === 'dark-mode'
                }"
            >
                <i
                    class="fa-regular fa-bell fs-6"
                    (click)="showMenu = true"
                ></i>
            </span>
            <span
                *ngIf="
                    !clientInfo ||
                    (clientInfo &&
                        clientInfo?.client &&
                        clientInfo?.client?.picture)
                "
                role="button"
                class="ms-4 ms-md-5 ms-lg-2 me-md-1"
                style="border-radius: 50%; height: 25px; width: 25px"
                (click)="displayUserInfo(); toggleAsideMenu(true)"
                [ngClass]="{
                    'text-primary-light':
                        theme === 'magis-light' || theme === 'magis-dark',
                    white: theme === 'light-mode' || theme === 'dark-mode'
                }"
            >
                <img
                    [src]="clientInfo?.client.picture"
                    class="image rounded-circle"
                />
            </span>
            <span
                *ngIf="
                    clientInfo &&
                    clientInfo.client &&
                    !clientInfo?.client?.picture
                "
                role="button"
                class="ms-4 ms-md-5 ms-lg-2 p-2 me-md-1"
                (click)="displayUserInfo(); toggleAsideMenu(true)"
                [ngClass]="{
                    'text-primary-light':
                        theme === 'magis-light' || theme === 'magis-dark',
                    white: theme === 'light-mode' || theme === 'dark-mode'
                }"
            >
                <i
                    class="fa-regular fa-circle-user fs-6"
                    (click)="showMenu = true"
                ></i>
            </span>

            <span
                (click)="showPlateform(); toggleAsideMenu(true)"
                role="button"
                class="ms-4 ms-md-5 ms-lg-2 p-2 me-md-1"
                [ngClass]="{
                    'text-primary-light':
                        theme === 'magis-light' || theme === 'magis-dark',
                    white: theme === 'light-mode' || theme === 'dark-mode',
                    'px-4 ps-3': plateform === 'workStation'
                }"
            >
                <img
                    [src]="gridFill"
                    class="mb-1 show-plateform image"
                    [ngStyle]="{
                        filter:
                            plateform !== 'workStation' ? 'invert(100%)' : ''
                    }"
                    style="width: 20px"
                />
            </span>
        </div>

        <!-- chat popup for page under construction -->

        <div
            *ngIf="chatNotFoundPopup"
            class="position-absolute shadow level-1 border sm-radius py-3 px-5 overflow-hidden"
            style="right: 1%; top: 57px; z-index: 20"
        >
            <app-not-found-page
                action="close"
                (close)="getPopupEvent($event)"
            ></app-not-found-page>
        </div>

        <!-- notification popup for page under construction -->
        <div
            *ngIf="notificationNotFoundPopup"
            class="position-absolute shadow level-1 border sm-radius py-3 px-5 overflow-hidden"
            style="right: 1%; top: 57px; z-index: 20"
        >
            <app-not-found-page
                action="close"
                (close)="getPopupEvent($event)"
            ></app-not-found-page>
        </div>
    </div>
</div>

<!-- **__End of the three sections that are flexibly displayed and spaced at the edges with proportional margins between them__** -->

<!--* Start of sections that are not depend to others and are positioned fixed as cards -->
<div>
    <!--*corporates card-->
    <div
        class="connectededOrganization pb-2 pt-2 ms-2 mt-2 rounded position-fixed"
        *ngIf="
            showCorporatesSection && organization && plateform === 'workStation'
        "
        style="width: 300px; height: 296px; overflow: auto; z-index: 30"
    >
        <!--*logo and title of the connected organization inside the corporates card-->
        <div
            class="w-100 d-flex justify-content-center mb-0"
            style="height: 50px"
        >
            <div class="d-flex">
                <div
                    style="width: 100px; height: 100%"
                    class="d-flex justify-content-center rounded"
                >
                    <img
                        [src]="organization.institution_client.picture"
                        class="img-fluid"
                        style="width: 100%"
                    />
                </div>
                <div
                    style="
                        margin-top: auto;
                        margin-bottom: auto;
                        line-height: 15px;
                    "
                    *ngIf="plateform === 'workStation'"
                    class="ps-1"
                >
                    <b class="text-dark">{{
                        organization.institution_client.client_full_name
                    }}</b
                    ><br />
                    <b style="font-size: 13px" class="text-dark">{{
                        organization.company_type_code
                    }}</b>
                </div>
            </div>
        </div>

        <!--*Icon that shows that the operator has no access to more than one organization-->
        <div
            class="w-100 d-flex justify-content-center mb-2 mt-5"
            *ngIf="corporates.length < 1 && plateform === 'workStation'"
        >
            <div>
                <span class="fa-stack text-white" style="font-size: 65px">
                    <i class="fa fa-business-time fa-stack-1x fs-1"></i>
                    <i class="fa fa-ban fa-stack-2x fs-1"></i>
                </span>
            </div>
        </div>

        <!--*corporates list-->
        <div
            *ngIf="corporates.length === 1 || corporates.length > 1"
            class="mt-4 pb-2"
        >
            <div style="width: 100%; display: flex; justify-content: center">
                <b class="text-primary text-center mb-2"
                    ><i class="fa-solid fa-business-time me-1"></i>
                    <span style="font-size: 23px">Corporates</span>
                </b>
            </div>

            <!--*corporates list loop-->
            <div
                class="w-100 d-flex justify-content-center mb-2"
                *ngFor="let corporate of corporates"
                style="height: 58px; width: 160px"
            >
                <div
                    class="d-flex"
                    role="button"
                    (click)="switchCorporate(corporate)"
                >
                    <div
                        style="width: 100px; height: 100%"
                        class="d-flex justify-content-center m-0"
                    >
                        <img
                            [src]="
                                corporate.organization.institution_client
                                    .picture
                            "
                            class="img-fluid level-2"
                            style="
                                border-radius: 10px 0px 0px 10px;
                                width: 100%;
                            "
                        />
                    </div>
                    <div
                        style="
                            margin-top: auto;
                            margin-bottom: auto;
                            line-height: 15px;
                            width: 170px;
                            border-radius: 0px 10px 10px 0px;
                        "
                        *ngIf="plateform === 'workStation'"
                        class="header-color h-100 pt-2 px-2"
                    >
                        <b class="text-dark">{{
                            corporate.organization.institution_client
                                .client_full_name
                        }}</b
                        ><br />
                        <b style="font-size: 13px" class="text-dark">{{
                            corporate.organization.company_type_code
                        }}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--*user profile card-->
    <div
        class="float-end m-2 profile-card text-dark pe-3 ps-1"
        style="border-radius: 10px 0px 0px 10px; width: 302px"
        [ngClass]="{
            'level-2': plateform === 'workStation',
            'level-1': plateform !== 'workStation'
        }"
        *ngIf="showUserInfo && userInfo"
    >
        <!-- first section for user info -->
        <section>
            <div
                class="pt-2 pb-2 d-flex justify-content-end"
                style="border-radius: 5px 5px 0px 0px"
            >
                <span
                    role="button"
                    (click)="showUserInfo = false"
                    class="float-end text-dark fs-4"
                    style="z-index: 50"
                    title="close"
                >
                    <i class="fa fa-xmark"></i>
                </span>
            </div>
            <!--user infos-->
            <hr class="w-100 text-secondary" />
            <div
                style="transform: translateY(-60%)"
                class="d-flex align-items-end justify-content-end ms-4 mb-0"
            >
                <div
                    class="p-1 rounded-circle"
                    [ngClass]="{
                        'level-2': plateform === 'workStation',
                        'level-1': plateform !== 'workStation'
                    }"
                >
                    <div
                        class="d-flex align-items-end me-0 ms-0 p-2 rounded-circle border border-secondary"
                        [ngClass]="{
                            'level-2': plateform === 'workStation',
                            'level-1': plateform !== 'workStation'
                        }"
                    >
                        <!--user profile picture-->
                        <div
                            class="text-center border border-secondary d-flex flex-column align-items-center justify-content-center rounded-circle profile-picture-container"
                            [ngClass]="{
                                'level-1': plateform === 'workStation',
                                'level-2': plateform !== 'workStation'
                            }"
                        >
                            <img
                                src="/assets/images/userprofile.png"
                                class="image border border-secondary rounded-circle"
                                *ngIf="!clientInfo?.client?.picture"
                            />
                            <img
                                *ngIf="clientInfo?.client?.picture"
                                [src]="clientInfo?.client?.picture"
                                class="image border border-secondary rounded-circle"
                            />
                            <!-- <img
                                [src]="operator.picture"
                                class="img-fluid border border-secondary"
                                style="width: 78px; border-radius: 50%"
                                *ngIf="organization"
                            /> -->
                        </div>
                    </div>
                </div>

                <div
                    class="ms-0 d-grid w-100 mb-2"
                    style="line-height: 20px; transform: translateY(-9%)"
                >
                    <span
                        class="ms-2 mb-2 text-dark w-100 text-sm-md"
                        style="word-break: break-word"
                        >{{ userInfo.username }}</span
                    >

                    <span
                        style="word-break: break-word"
                        class="ms-2 text-secondary w-100 text-xsm"
                    >
                        <span>{{ userInfo.email }}</span> </span
                    ><br />
                    <span
                        class="ms-2 text-secondary text-xsm"
                        *ngIf="clientInfo && clientInfo?.client"
                        >{{ clientInfo?.client?.clientCode }}</span
                    >
                </div>
            </div>
        </section>

        <!-- second section for clickable link  -->
        <section
            class="px-3 overflow-y-auto d-flex flex-column h-100"
            style="margin-top: -20%"
        >
            <section class="flex-grow-1">
                <div
                    class="w-100 my-3 d-flex justify-content-between align-items-center"
                >
                    <a>
                        <div
                            role="button"
                            class="d-flex align-items-center"
                            style="font-size: 18px"
                        >
                            <i
                                class="fa-solid text-secondary fa-user-circle me-2"
                            ></i>
                            <b class="text-dark ms-1" i18n>Profile</b>
                        </div>
                    </a>
                </div>
                <div
                    class="w-100 my-3 d-flex justify-content-between align-items-center"
                >
                    <a routerLink="/b/settings">
                        <div
                            role="button"
                            class="d-flex align-items-center"
                            style="font-size: 18px"
                        >
                            <i class="fa-solid text-secondary fa-gear me-2"></i>
                            <b class="text-dark ms-1" i18n>Settings</b>
                        </div>
                    </a>
                </div>
                <hr />
                <div
                    class="w-100 d-flex justify-content-between align-items-center mt-3"
                >
                    <div
                        class="d-flex align-items-center"
                        style="font-size: 18px"
                    >
                        <i class="fa-solid text-secondary fa-language me-2"></i>
                        <b class="text-dark" i18n>Language</b>
                    </div>
                    <select
                        [formControl]="selectedLanguage"
                        style="width: 60px"
                        (click)="changeLanguage()"
                    >
                        <option value="en">En</option>
                        <option value="fr">Fr</option>
                    </select>
                </div>
                <div
                    class="w-100 d-flex my-3 justify-content-between align-items-center"
                >
                    <div
                        class="d-flex align-items-center"
                        style="font-size: 18px"
                    >
                        <span
                            *ngIf="
                                theme === 'magis-light' ||
                                theme === 'light-mode'
                            "
                        >
                            <span class="me-2"
                                ><img
                                    src="{{ lightModeImage }}"
                                    alt="light logo"
                            /></span>
                            <b class="text-dark" i18n>Light Mode</b>
                        </span>
                        <span
                            *ngIf="
                                theme === 'magis-dark' || theme === 'dark-mode'
                            "
                        >
                            <i class="fa-solid text-secondary fa-moon me-2"></i>
                            <b class="text-dark" i18n>Dark Mode</b>
                        </span>
                    </div>
                    <div>
                        <div
                            class="clickable"
                            title="Switch Theme"
                            (click)="onToggle()"
                        >
                            <img [src]="themeLogo" />
                        </div>
                    </div>
                </div>
                <hr />
                <div
                    class="w-100 my-3 d-flex justify-content-between align-items-center"
                >
                    <a>
                        <div
                            role="button"
                            class="d-flex align-items-center"
                            style="font-size: 18px"
                        >
                            <i class="fa-solid text-secondary fa-flag me-2"></i>
                            <b class="text-dark ms-1" i18n>Report a problem</b>
                        </div>
                    </a>
                </div>

                <hr />
                <div
                    class="w-100 d-flex justify-content-between text-dark"
                    style="font-size: 18px"
                    title="Logout"
                    (click)="logout()"
                    role="button"
                >
                    <span>
                        <i
                            class="fa-solid text-secondary me-2 fa-right-from-bracket"
                        ></i>
                        <b i18n>Logout</b>
                    </span>
                </div>
            </section>

            <div class="mt-3">
                <!--user profile footer-->
                <div class="mt-2 px-2 bg-white text-secondary text-sm-md">
                    <div class="d-flex justify-content-center">
                        <app-footer [showCopyRight]="false"></app-footer>
                    </div>
                </div>
            </div>
        </section>

        <!--*shwitchPlateform card-->
    </div>

    <!--plateform infos-->
    <div>
        <div
            class="float-end plateforn-card lg-radius border"
            style="border-width: 10px !important"
            [ngClass]="{
                'level-2': plateform === 'workStation',
                'level-1': plateform !== 'workStation',
                'border-primary-onamob': plateform === 'onamob',
                'border-primary-2': plateform !== 'onamob'
            }"
            *ngIf="showPlateformPopup"
        >
            <div class="h-100 p-3 py-4 overflow-y-auto">
                <div class="d-flex flex-column h-100">
                    <div class="px-1 flex-grow-2">
                        <div
                            class="row justify-content-between align-items-center"
                        >
                            <div
                                class="col-4 d-flex flex-column align-items-center p-2 sm-radius"
                                role="button"
                                [ngClass]="{
                                    'bg-primary-3 ': plateform === 'home',
                                    'icons-color': plateform !== 'home'
                                }"
                                (click)="switchPlateform('home')"
                            >
                                <div class="d-flex justify-content-center">
                                    <span
                                        [ngClass]="{
                                            'text-primary':
                                                plateform === 'home',
                                        }"
                                    >
                                        <i
                                            class="fs-1 fa-solid fa-house-chimney-user"
                                        ></i>
                                    </span>
                                </div>
                                <div
                                    class="text-xsm text-center text-dark mt-2"
                                    [ngClass]="{
                                        'text-all-dark': plateform === 'home'
                                    }"
                                    i18n
                                >
                                    Community
                                </div>
                            </div>
                            <div
                                class="col-4 d-flex flex-column align-items-center p-2 sm-radius"
                                role="button"
                                [ngClass]="{
                                    'bg-primary-3':
                                        plateform === 'onlineBanking'
                                }"
                                (click)="switchPlateform('onlineBanking')"
                            >
                                <div class="d-flex justify-content-center">
                                    <div>
                                        <img
                                            *ngIf="
                                                plateform === 'onlineBanking'
                                            "
                                            src="assets/images/ihela-b.svg"
                                            alt=""
                                        />
                                        <img
                                            *ngIf="
                                                plateform !== 'onlineBanking'
                                            "
                                            src="assets/images/ihelap.svg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div
                                    class="text-xsm text-center mt-2"
                                    [ngClass]="{
                                        'text-all-dark':
                                            plateform === 'onlineBanking'
                                    }"
                                    i18n
                                >
                                    Fin. services
                                </div>
                            </div>
                            <div
                                class="col-4 d-flex flex-column align-items-center p-2 sm-radius"
                                role="button"
                                [ngClass]="{
                                    'bg-primary-3': plateform === 'market',
                                    'icons-color': plateform !== 'market'
                                }"
                                (click)="switchPlateform('market')"
                            >
                                <div class="d-flex justify-content-center">
                                    <span
                                        [ngClass]="{
                                            'text-primary':
                                                plateform === 'market',
                                        }"
                                    >
                                        <i
                                            class="fa-solid fa-cart-shopping fs-1 fs-1"
                                        ></i>
                                    </span>
                                </div>

                                <div
                                    class="text-xsm text-center mt-2"
                                    [ngClass]="{
                                        'text-all-dark': plateform === 'market'
                                    }"
                                    i18n
                                >
                                    Market Place
                                </div>
                            </div>
                        </div>
                        <div
                            class="row justify-content-between align-items-center mt-4 pt-3"
                        >
                            <div
                                class="col-4 d-flex flex-column align-items-center p-2 sm-radius"
                                role="button"
                                (click)="switchPlateform('workStation')"
                                [ngClass]="{
                                    'bg-primary-3': plateform === 'workStation'
                                }"
                            >
                                <div class="d-flex justify-content-center">
                                    <span
                                        [ngClass]="{
                                            'text-primary ':
                                                plateform === 'workStation',
                                            'icons-color':
                                                plateform !== 'workStation'
                                        }"
                                    >
                                        <i class="fa-solid fa-desktop fs-1"></i>
                                    </span>
                                </div>
                                <div class="text-xsm text-center mt-2" i18n>
                                    WorkStation
                                </div>
                            </div>

                            <div
                                class="col-4 d-flex flex-column align-items-center p-2 sm-radius"
                                role="button"
                                (click)="switchPlateform('onamob')"
                                [ngClass]="{
                                    'bg-onamob-2': plateform === 'onamob'
                                }"
                            >
                                <div class="d-flex justify-content-center">
                                    <span
                                        [ngClass]="{
                                            'text-onamob':
                                                plateform === 'onamob',
                                            'icons-color':
                                                plateform !== 'onamob'
                                        }"
                                    >
                                        <i
                                            class="fa-solid fa-mobile-screen-button fs-1"
                                        ></i>
                                    </span>
                                </div>
                                <div
                                    class="text-xsm text-center mt-2"
                                    [ngClass]="{
                                        'text-all-dark': plateform === 'onamob'
                                    }"
                                    i18n
                                >
                                    MVNO
                                </div>
                            </div>

                            <div
                                class="col-4 d-flex flex-column align-items-center p-2 sm-radius"
                            >
                                <div class="d-flex justify-content-center">
                                    <i
                                        class="fa-solid fa-square-poll-vertical fs-1 icons-color"
                                    ></i>
                                </div>
                                <div i18n>Ser .Status</div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="d-flex justify-content-center flex-column flex-grow-1"
                    >
                        <hr class="col-8 mx-auto" />
                        <div class="p-2">
                            <div class="text-md text-dark text-center">
                                <b i18n>Download Mobiles Apps</b>
                            </div>
                            <div
                                class="d-flex justify-content-between col-lg-10 mx-auto mt-4"
                            >
                                <div>
                                    <a
                                        href="https://apps.apple.com/fr/app/ihel%C3%A1-ryanje-app/id6470385289"
                                        target="_blank"
                                    >
                                        <img
                                            class="image"
                                            src="assets/images/app-store.svg"
                                            alt="Go to ihela app-store"
                                        />
                                    </a>
                                </div>
                                <div>
                                    <!-- href="https://play.google.com/store/apps/details?id=com.ubuviz.ihera_mobile" -->
                                    <a
                                        href="https://cdn.magis.bi/iHela_mobile_app/iHelaRyanjeApp.apk"
                                        target="_blank"
                                    >
                                        <img
                                            class="image"
                                            src="assets/images/playstore.svg"
                                            alt="Go to ihela playstore"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
